<template>
  <li
    class="wizard__navigation-item"
    :class="{
      'wizard__navigation-item--disabled': isDisabled,
      'wizard__navigation-item--active': isActive,
    }"
    @click="onClick"
  >
    {{ label }}
  </li>
</template>

<script>
export default {
  name: "WizardNavigationItem",
  props: {
    label: {
      type: String,
      required: true,
      default: "",
    },
    isActive: {
      type: Boolean,
      required: true,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  methods: {
    onClick() {
      this.$emit("on-item-selected");
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/colors";

.wizard__navigation-item {
  text-align: right;
  padding: 0.75rem 1rem;
  padding-right: 2.5rem;
  position: relative;
  cursor: pointer;
  color: $medium-gray;
  display: block;
  pointer-events: auto;

  &:after {
    position: absolute;
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    right: 0;
    top: 50%;
    margin-top: -4px;
    border-radius: 50%;
    outline: solid 4px transparent;
    background: $blue;
  }

  &--disabled {
    pointer-events: none;
    color: $disabled;

    &:after {
      background: $disabled;
    }
  }

  &--active {
    color: $blue;

    &:after {
      outline: solid 4px $light-blue-tint3;
    }
  }
}
</style>