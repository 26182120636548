function Audience(data) {
	data = data || null;
	let model = {
		id: null,
		name: null,
		specialty: null,
		condition: null,
		condition_id: null,
		total_population: null,
		total_population_female: null,
		total_population_male: null,
		total_population_0_17: null,
		total_population_18_44: null,
		total_population_45_64: null,
		total_population_65_over: null,
		total_population_80_over: null,
		total_population_18_over: null,
		total_population_45_over: null,
		total_private_insurance: null,
		total_public_insurance: null,
		total_medicare: null,
		total_uninsured: null,
		total_other_insurance: null,
		incidence_min: null,
		incidence_max: null,
		prevalence_min: null,
		prevalence_max: null,
		county_id: null,
		county_name: null,
		radius: 30,
		state_code: null,
		access: [],
		environment: [],
		demographics: null,
		created_at: null,
		updated_at: null
	}

	if (data) {
		for (let key in model) {
			model[key] =
				typeof data[key] != "undefined" && data[key]
					? data[key]
					: model[key];
		}
	}

	return model;
}

export default Audience;