<template>
  <div class="audiences">
    <Loading :active="isLoading" :fullscreen="true" />

    <Wizard
      class="form-control"
      :active="showWizard"
      :views="wizardViews"
      @dismiss="showWizard = false"
    >
      <template v-slot:name>
        <p class="wizard__view-title">Audience Name</p>
        <h2>What is the name of this audience?</h2>
        <p class="wizard__view-description">
          Give this audience a name that is describes the condition, the
          location, and any attributes specific to healthcare access or physical
          environment. You may follow the naming convention Condition - Location
          - Attributes.
        </p>
        <input
          type="text"
          v-model="$_audiences_audience.name"
          placeholder="e.g. Breast Cancer - Fulton County, GA - High Access, Suburban"
        />
      </template>
      <template v-slot:location>
        <p class="wizard__view-title">Location</p>
        <h2>Where is the target audience located?</h2>
        <p class="wizard__view-description">
          Search for a specific location by entering a county name.
        </p>
        <AutocompleteField
          v-model="$_audiences_audience.county_id"
          :options="locationOptions"
          @on-input="onLocationInput"
          :placeholder="'Search by county'"
        />

        <h3>How far will this audience likely travel for treatment?</h3>
        <p class="wizard__view-description">
          Patients travel farther for the treatment of chronic conditions like
          Cancer or Heart Disease.
        </p>
        <select v-model="$_audiences_audience.radius">
          <option value="30">30 Miles</option>
          <option value="60">60 Miles</option>
          <option value="90">90 Miles</option>
          <option value="120">120 Miles</option>
        </select>

        <h3>Would you like to restrict the audience by state?</h3>
        <p class="wizard__view-description">
          Some insurance plans restrict coverage to a patient's home state.
        </p>
        <select v-model="$_audiences_audience.state_code">
          <option :value="null">Select a State</option>
          <option v-for="(state, key) in states" :key="`${key}`" :value="key">
            {{ state }}
          </option>
        </select>
      </template>

      <template v-slot:condition>
        <p class="wizard__view-title">Condition or Procedure</p>
        <h2>How should the audience be identified?</h2>
        <p class="wizard__view-description">
          Select a service line to view specific health conditions and
          procedures.
        </p>
        <select v-model="$_audiences_audience.specialty">
          <option :value="null">Select a Service Line</option>
          <option
            v-for="(specialty, index) in specialties"
            :key="`specialty-${index}`"
            :value="specialty"
          >
            {{ specialty }}
          </option>
        </select>

        <h3>
          What health condition or procedure will define the target audience?
        </h3>
        <p class="wizard__view-description">
          Select a specific condition or procedure to estimate case
          opportunities and define the target audience.
        </p>
        <select
          :disabled="!$_audiences_audience.specialty"
          v-model="$_audiences_audience.condition_id"
        >
          <option :value="null">Select a Condition</option>
          <option
            v-for="(condition, index) in conditions"
            :key="`condition-${index}`"
            :value="condition.id"
          >
            {{ condition.name }}
          </option>
        </select>
      </template>

      <template v-slot:access>
        <p class="wizard__view-title">Healthcare Access</p>
        <h2>How does the audience access healthcare?</h2>
        <p class="wizard__view-description">
          Select options that describe the audience's ability to access
          healthcare. This includes the ability to communicate with physicians,
          access reliable transportation, pay for services, etc.
        </p>
        <div class="wizard__view-checkbox-group">
          <input
            type="checkbox"
            v-model="$_audiences_audience.access"
            value="low"
          /><label>Poor</label>
          <input
            type="checkbox"
            v-model="$_audiences_audience.access"
            value="moderate"
          /><label>Moderate</label>
          <input
            type="checkbox"
            v-model="$_audiences_audience.access"
            value="high"
          /><label>High</label>
        </div>

        <h3>What is the audience's physical environment?</h3>
        <p class="wizard__view-description">
          Select the options that describe the audience's physical environment.
        </p>
        <div class="wizard__view-checkbox-group">
          <input
            type="checkbox"
            v-model="$_audiences_audience.environment"
            value="rural"
          /><label>Rural</label>
          <input
            type="checkbox"
            v-model="$_audiences_audience.environment"
            value="suburban"
          /><label>Suburban</label>
          <input
            type="checkbox"
            v-model="$_audiences_audience.environment"
            value="urban"
          /><label>Urban</label>
        </div>
      </template>

      <template v-slot:overview>
        <p class="wizard__view-title">Audience Overview</p>
        <template v-if="$_audiences_audience.demographics">
          <p class="wizard__chart-label">
            <span
              ><strong>{{ caseOpportunities }}</strong> Total Case
              Opportunities</span
            >
            <select v-model="$data.$_audiences_caseOpportunityFilter">
              <option value="min">Minimum Estimate</option>
              <option value="max">Maximum Estimate</option>
            </select>
          </p>
          <div
            class="persona"
            v-for="(persona, index) in $_audiences_personas"
            :key="`persona-${index}`"
          >
            <PersonaListItem :persona="persona" />
          </div>
        </template>
      </template>
    </Wizard>

    <div class="hero hero--identify">
      <div class="container">
        <h2>Audiences</h2>
        <div class="controls-wrapper form-control">
          <div class="select">
            <select v-model="specialtyFilter">
              <option :value="null">All Service Lines</option>
              <option
                v-for="(specialty, index) in specialties"
                :key="`specialty-${index}`"
                :value="specialty"
              >
                {{ specialty }}
              </option>
            </select>
          </div>
          <div class="select">
            <select v-model="conditionFilter" :disabled="!specialtyFilter">
              <option :value="null">All Conditions</option>
              <option
                v-for="(condition, index) in filteredConditions"
                :key="`condition-${index}`"
                :value="condition.id"
              >
                {{ condition.name }}
              </option>
            </select>
          </div>
          <button class="button" @click="onNewAudience">New Audience</button>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="audiences-wrapper">
        <div
          class="audience"
          v-for="(audience, index) in filteredAudiences"
          :key="`audience-${index}`"
        >
          <ActionCard
            :heading="audience.name"
            :subheading="audience.condition"
            :icon="audience.specialty"
            :color="getColorBySpecialty(audience.specialty)"
            :actions="[
              {
                label: 'Explore',
                icon: 'bar-chart',
                onClick: () => {
                  $router.push(`audiences/${audience.id}`);
                },
              },
              {
                label: 'Edit',
                icon: 'edit',
                onClick: () => {
                  onEditAudience(audience);
                },
              },
            ]"
          >
            <div class="audience__tags">
              <Pill
                :count="tag"
                :label="true"
                v-for="(tag, i) in getTags(audience)"
                :key="`audience-${index}-tag-${i}`"
              />
            </div>
          </ActionCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import AutocompleteField from "@/components/fields/AutocompleteField";
import statesData from "@/data/states.json";
import Wizard from "@/components/wizard/Wizard";
import ActionCard from "@/components/ActionCard";
import Pill from "@/components/Pill";
import Audience from "@/models/Audience";
import { audiences } from "@/mixins/audiences";
import { content } from "@/mixins/content";
import PersonaListItem from "@/components/PersonaListItem";

export default {
  name: "Audiences",
  mixins: [audiences, content],
  components: {
    Loading,
    AutocompleteField,
    Wizard,
    ActionCard,
    Pill,
    PersonaListItem,
  },
  data() {
    return {
      showWizard: false,
      caseOpportunityFilter: "min",
      specialtyFilter: null,
      conditionFilter: null,
      wizardViews: [
        {
          key: "name",
          label: "Audience Name",
          isDisabled: () => {
            return false;
          },
          nextDisabled: () => {
            return !this.$_audiences_audience.name;
          },
          beforeEnter: null,
          beforeExit: null,
        },
        {
          key: "location",
          label: "Location",
          isDisabled: () => {
            return !this.$_audiences_audience.name;
          },
          nextDisabled: () => {
            return !this.$_audiences_audience.county_id;
          },
          beforeEnter: null,
          beforeExit: this.onGetLocations,
        },
        {
          key: "condition",
          label: "Condition or Procedure",
          isDisabled: () => {
            return (
              !this.$_audiences_audience.name ||
              !this.$_audiences_audience.county_id ||
              !this.locationNeighbors.length
            );
          },
          nextDisabled: () => {
            return !this.$_audiences_audience.condition_id;
          },
          beforeEnter: null,
          beforeExit: null,
        },
        {
          key: "access",
          label: "Healthcare Access",
          isDisabled: () => {
            return (
              !this.$_audiences_audience.name ||
              !this.$_audiences_audience.condition_id ||
              !this.$_audiences_audience.county_id ||
              !this.locationNeighbors.length
            );
          },
          beforeEnter: null,
          beforeExit: null,
        },
        {
          key: "overview",
          label: "Audience Overview",
          isDisabled: () => {
            return (
              !this.$_audiences_audience.name ||
              !this.$_audiences_audience.condition_id ||
              !this.$_audiences_audience.county_id ||
              !this.locationNeighbors.length ||
              !this.$_audiences_audience.demographics
            );
          },
          beforeEnter: this.onGetCaseOpportunities,
          beforeExit: null,
          actions: [
            {
              label: "Save",
              onAction: this.onSave,
            },
          ],
        },
      ],
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters["audiences/loading"];
    },
    audiences() {
      return this.$store.getters["audiences/audiences"];
    },
    filteredAudiences() {
      return this.audiences.filter((item) => {
        // Filter by most granular option first
        if (this.conditionFilter) {
          return item.condition_id == this.conditionFilter;
        }

        if (this.specialtyFilter) {
          return item.specialty == this.specialtyFilter;
        }

        return item;
      });
    },
    states() {
      return statesData;
    },
    locationOptions() {
      // If no options exist, and location is set, return the selected location as an option
      let options = this.$store.getters["audiences/locationOptions"];
      if (!options.length && this.$_audiences_audience.county_id) {
        options = [
          {
            value: this.$_audiences_audience.county_id,
            name: this.$_audiences_audience.county_name,
          },
        ];
      }
      return options;
    },
    locationNeighbors() {
      return this.$store.getters["audiences/locations"];
    },
    conditionOptions() {
      return this.$store.getters["audiences/conditions"];
    },
    specialties() {
      let list = [];

      this.conditionOptions.map((item) => {
        if (list.indexOf(item.specialty) === -1) {
          list.push(item.specialty.toLowerCase());
        }
      });

      return list;
    },
    conditions() {
      let list = [];

      if (this.$_audiences_audience.specialty) {
        list = this.conditionOptions.filter((item) => {
          return item.specialty === this.$_audiences_audience.specialty;
        });
      }

      return list;
    },
    filteredConditions() {
      let list = [];

      if (this.specialtyFilter) {
        list = this.conditionOptions.filter((item) => {
          return item.specialty === this.specialtyFilter;
        });
      }

      return list;
    },
    caseOpportunities() {
      if (this.metrics) {
        return this.$_audiences_getCaseOpportunities(this.metrics);
      }

      return 0;
    },
    metrics() {
      return this.$store.getters["audiences/metrics"];
    },
  },
  methods: {
    onNewAudience() {
      const newAudience = new Audience();
      this.$store.dispatch("audiences/setSelectedAudience", newAudience);
      this.showWizard = true;
    },
    onEditAudience(audience) {
      const newAudience = new Audience(audience);
      this.$store.dispatch("audiences/setSelectedAudience", newAudience);

      // Previously created audiences will not have a county id
      if (newAudience.county_id) {
        this.onGetLocations().then((res) => {
          if (res.status < 300) {
            this.showWizard = true;
          }
        });
      } else {
        this.showWizard = true;
      }
    },
    onLocationInput(value) {
      if (value && value.length) {
        this.$store.dispatch("audiences/searchLocations", { term: value });
      }
    },
    onGetLocations() {
      if (this.$_audiences_audience.county_id) {
        return this.$store.dispatch("audiences/onGetLocations", {
          location: this.$_audiences_audience.county_id,
          state: this.$_audiences_audience.state_code,
          radius: this.$_audiences_audience.radius,
        });
      }
    },
    onGetCaseOpportunities() {
      if (this.$_audiences_audience.county_id) {
        // Get ids of location neighbors
        // Join as string
        let counties = this.locationNeighbors.map((item) => {
          return item.id;
        });

        counties = counties.join(",");

        if (this.$_audiences_audience.condition_id) {
          return this.$store.dispatch("audiences/onGetDemographics", {
            counties: counties,
            condition: this.$_audiences_audience.condition_id,
            access: this.$_audiences_audience.access,
            environment: this.$_audiences_audience.environment,
          });
        }
      }
    },
    getConditionNameById(id) {
      if (this?.conditionOptions?.length) {
        let match = this.conditionOptions.find((item) => {
          return item.id == id;
        });

        if (typeof match != "undefined") {
          return match.name;
        }
      }

      return null;
    },
    getCountyNameById(id) {
      if (this?.locationOptions?.length) {
        let match = this.locationOptions.find((item) => {
          return item.value == id;
        });

        if (typeof match != "undefined") {
          return match.name;
        }
      }

      return null;
    },
    onSave() {
      // Format and save
      let data = {
        id: this.$_audiences_audience.id,
        name: this.$_audiences_audience.name,
        specialty: this.$_audiences_audience.specialty,
        condition_id: this.$_audiences_audience.condition_id,
        condition: this.getConditionNameById(
          this.$_audiences_audience.condition_id
        ),
        total_population: this.metrics.total_population,
        total_population_female: this.metrics.total_population_female,
        total_population_male: this.metrics.total_population_male,
        total_population_0_17: this.metrics.total_population_0_17,
        total_population_18_44: this.metrics.total_population_18_44,
        total_population_45_64: this.metrics.total_population_45_64,
        total_population_65_over: this.metrics.total_population_65_over,
        total_population_80_over: this.metrics.total_population_80_over,
        total_population_18_over: this.metrics.total_population_18_over,
        total_population_45_over: this.metrics.total_population_45_over,
        total_private_insurance: this.metrics.total_private_insurance,
        total_public_insurance: this.metrics.total_public_insurance,
        total_medicare: this.metrics.total_medicare,
        total_uninsured: this.metrics.total_uninsured,
        total_other_insurance: this.metrics.total_other_insurance,
        incidence_min: this.metrics.incidence_min,
        incidence_max: this.metrics.incidence_max,
        prevalence_min: this.metrics.prevalence_min,
        prevalence_max: this.metrics.prevalence_max,
        county_id: this.$_audiences_audience.county_id,
        county_name: this.getCountyNameById(
          this.$_audiences_audience.county_id
        ),
        radius: this.$_audiences_audience.radius,
        state_code: this.$_audiences_audience.state_code,
        access: this.$_audiences_audience.access,
        environment: this.$_audiences_audience.environment,
        demographics: this.$_audiences_audience.demographics,
      };

      this.$store.dispatch("audiences/saveAudience", data).then((res) => {
        if (res.status < 300) {
          this.showWizard = false;
        }
      });
    },
    getTags(audience) {
      let tags = [];
      if (audience.county_name) {
        tags.push(audience.county_name);
      }

      if (audience.radius) {
        tags.push(`+${audience.radius}mi`);
      }

      if (audience.access) {
        audience.access.map((item) => {
          tags.push(`${item} Access`);
        });
      }

      if (audience.environment) {
        audience.environment.map((item) => {
          tags.push(item);
        });
      }

      return tags;
    },
  },
  mounted() {
    if (!this.conditionOptions.length) {
      this.$store.dispatch("audiences/getConditions");
    }

    if (!this.audiences.length) {
      this.$store.dispatch("audiences/getAudiences");
    }
  },
  watch: {
    specialtyFilter() {
      if (!this.specialtyFilter) {
        this.conditionFilter = null;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/colors";
@import "@/scss/hero";
@import "@/scss/button";
@import "@/scss/forms";

.audiences-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem;

  .audience {
    width: 33.3333%;
    padding: 0 1rem 2rem 1rem;

    &__tags {
      margin: -3px;
      .pill {
        margin: 3px;
      }
    }
  }
}
.wizard {
  &__content {
    h2 {
      font-size: 32px !important;
      margin-bottom: 1.5rem !important;
    }

    h3 {
      font-size: 18px !important;
      margin-bottom: 1rem !important;
      margin-top: 1rem;
      font-weight: 700;
    }

    select {
      text-transform: capitalize;
    }
  }

  &__view-title {
    font-size: 1.25rem;
    color: $medium-gray;
    margin-bottom: 3rem;
  }

  &__view-description {
    color: $medium-gray;
    margin-bottom: 1.5rem;
    line-height: 1.5;
  }

  &__view-checkbox-group {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;

    label {
      font-size: 1rem !important;
      font-weight: 400 !important;
      margin: 0 1rem 0 0.5rem;
    }

    input {
      margin: 0;
    }
  }

  &__chart-label {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    font-size: 0.8625rem;
    color: $medium-gray;

    select {
      max-width: 192px;
      margin-left: auto;
      margin-bottom: 0 !important;
      border-bottom: none !important;
      padding: 0 !important;
    }
  }

  .persona-list-item {
    margin-bottom: 1.5rem;
  }
}
</style>