<template>
  <transition name="fade-up">
    <div
      class="wizard"
      v-if="active"
      :class="{ 'wizard__transition--reverse': reverse }"
    >
      <button class="wizard__close" @click="$emit('dismiss', true)">
        <BaseSVG :src="require('@/assets/close-icon.svg')" />
      </button>
      <div class="wizard__container">
        <ul class="wizard__navigation">
          <WizardNavigationItem
            v-for="(view, index) in views"
            :key="`navigation-item-${index}`"
            :label="view.label"
            :isActive="activeView === view.key"
            :isDisabled="isNavigationDisabled(view.key)"
            @on-item-selected="onNavigate(view.key)"
          />
        </ul>
        <div class="wizard__content">
          <transition
            name="slide-up"
            v-for="(view, index) in views"
            :key="`view-${index}`"
          >
            <div class="wizard__view" v-if="activeView === view.key">
              <slot :name="view.key" />
              <div class="wizard__view-button-wrapper">
                <button
                  class="button outline"
                  @click="onNavigateBack"
                  v-if="index > 0"
                >
                  Back
                </button>
                <button
                  class="button"
                  @click="onNavigateNext"
                  v-if="index < views.length - 1"
                  :disabled="isNextDisabled(index)"
                >
                  Next
                </button>
                <template v-if="view.actions">
                  <button
                    class="button"
                    v-for="(action, actionIndex) in view.actions"
                    :key="`action-${actionIndex}`"
                    @click="action.onAction"
                  >
                    {{ action.label }}
                  </button>
                </template>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import BaseSVG from "@/components/base/BaseSVG";
import WizardNavigationItem from "@/components/wizard/WizardNavigationItem";

export default {
  name: "Wizard",
  props: {
    active: {
      type: Boolean,
      required: true,
      default: false,
    },
    views: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  components: {
    BaseSVG,
    WizardNavigationItem,
  },
  data() {
    return {
      activeView: null,
      reverse: false,
    };
  },
  methods: {
    isNavigationDisabled(key) {
      let navItem = this.views.find((item) => {
        return item.key === key;
      });

      if (typeof navItem != "undefined") {
        return navItem.isDisabled();
      }

      return false;
    },
    isNextDisabled(index) {
      let item = this.views[index];
      if (typeof item.nextDisabled == "function") {
        return item.nextDisabled();
      }

      return false;
    },
    onNavigate(view) {
      let currentIndex = this.views.findIndex((item) => {
        return item.key === this.activeView;
      });

      let nextIndex = this.views.findIndex((item) => {
        return item.key === view;
      });

      let current = this.views[currentIndex];
      let next = this.views[nextIndex];

      if (
        currentIndex < nextIndex &&
        (current.beforeExit || next.beforeEnter)
      ) {
        if (current.beforeExit && typeof current.beforeExit == "function") {
          const fn = current.beforeExit();
          if (typeof fn.then === "function") {
            fn.then((res) => {
              if (res) {
                this.changeView(nextIndex, currentIndex);
              }
            });
          } else {
            this.changeView(nextIndex, currentIndex);
          }
        }

        if (next.beforeEnter && typeof next.beforeEnter == "function") {
          const fn = next.beforeEnter();
          if (typeof fn.then === "function") {
            fn.then((res) => {
              if (res) {
                this.changeView(nextIndex, currentIndex);
              }
            });
          } else {
            this.changeView(nextIndex, currentIndex);
          }
        }
      } else {
        this.changeView(nextIndex, currentIndex);
      }
    },
    changeView(nextIndex, currentIndex) {
      this.reverse = nextIndex < currentIndex ? true : false;
      this.activeView = this.views[nextIndex].key;
    },
    onNavigateNext() {
      let current = this.views.findIndex((item) => {
        return item.key === this.activeView;
      });

      if (typeof current != "undefined" && current < this.views.length - 1) {
        this.onNavigate(this.views[current + 1].key);
      }
    },
    onNavigateBack() {
      let current = this.views.findIndex((item) => {
        return item.key === this.activeView;
      });

      if (typeof current != "undefined" && current > 0) {
        this.onNavigate(this.views[current - 1].key);
      }
    },
  },
  watch: {
    active() {
      if (this.active) {
        this.activeView = this.views[0].key;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/colors";

$wizardWidth: 1080px;
$wizardNavigationWidth: 320px;
$wizardContentWidth: 760px;

.wizard {
  position: fixed;
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  display: flex;
  align-items: center;
  z-index: 10;

  .slide-up-enter-active,
  .slide-up-leave-active {
    transition: opacity 0.425s,
      transform 0.425s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .slide-up-leave-active {
    width: 100%;
  }
  .slide-up-enter {
    opacity: 0;
    transform: translateY(100%);
  }
  .slide-up-enter-to {
    opacity: 1;
    transform: translateY(0);
  }
  .slide-up-leave-to {
    opacity: 0;
    transform: translateY(-100%);
  }

  &__transition--reverse {
    .slide-up-enter {
      transform: translateY(-100%);
    }

    .slide-up-leave-to {
      transform: translateY(100%);
    }
  }

  &__close {
    background: transparent;
    border: none;
    margin: 0;
    padding: 0;
    position: fixed;
    top: 1.5rem;
    right: 1.5rem;
    cursor: pointer;

    svg {
      fill: $medium-gray;
      width: 1rem;
    }
  }

  &__navigation {
    position: fixed;
    left: calc((100% - #{$wizardWidth}) * 0.5);
    height: 100%;
    width: $wizardNavigationWidth;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-item {
      text-align: right;
      padding: 0.75rem 1rem;
      padding-right: 2.5rem;
      position: relative;
      cursor: pointer;
      color: $medium-gray;

      &:after {
        position: absolute;
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        right: 0;
        top: 50%;
        margin-top: -4px;
        border-radius: 50%;
        outline: solid 4px transparent;
        background: $blue;
      }

      &--disabled {
        pointer-events: none;
        color: $disabled;

        &:after {
          background: $disabled;
        }
      }

      &--active {
        color: $blue;

        &:after {
          outline: solid 4px $light-blue-tint3;
        }
      }
    }
  }

  &__container {
    display: flex;
    width: $wizardWidth;
    min-height: 100%;
    margin: 0 auto;
    padding-left: $wizardNavigationWidth;
  }

  &__content {
    position: relative;
    width: $wizardContentWidth;
    min-height: 100%;
  }

  &__view {
    position: absolute;
    width: $wizardContentWidth;
    padding: 6rem 0 6rem 6rem;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__view-title {
    font-size: 1.25rem;
    color: $medium-gray;
    margin-bottom: 3rem;
  }

  &__view-button-wrapper {
    margin-top: 3rem;

    .button {
      margin-right: 0.5rem;
    }
  }
}
</style>