<template>
  <div class="autocomplete-field">
    <input
      type="text"
      v-model="term"
      v-if="!value"
      @input="$emit('on-input', term)"
      :placeholder="placeholder"
    />
    <span v-else class="chip"
      >{{ label }}
      <button class="chip__clear" @click="onClear">
        <BaseSVG :src="require('@/assets/close-icon.svg')" />
      </button>
    </span>
    <transition name="fade-up">
      <div
        class="autocomplete-field__options"
        :class="{ 'autocomplete-field__options--loading': isLoading }"
        v-if="term.length && !value"
      >
        <ul>
          <li
            class="autocomplete-field__option"
            @click="$emit('input', option.value)"
            v-for="(option, index) in options"
            :key="`option-${index}`"
          >
            {{ option.name }}
          </li>
        </ul>
        <Loading :active="isLoading" :fullscreen="false" :small="true" />
      </div>
    </transition>
  </div>
</template>

<script>
import BaseSVG from "@/components/base/BaseSVG";
import Loading from "@/components/Loading";

export default {
  name: "AutocompleteField",
  props: {
    value: {},
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
    placeholder: {
      type: String,
      required: false,
    },
  },
  components: {
    BaseSVG,
    Loading,
  },
  data() {
    return {
      term: "",
    };
  },
  computed: {
    label() {
      if (!this.value) {
        return null;
      }

      let selected = this.options.find((item) => {
        return item.value === this.value;
      });

      return selected?.name;
    },
    isLoading() {
      return !this.options.length;
    },
  },
  methods: {
    onClear() {
      this.term = "";
      this.$emit("input", null);
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/colors";

.autocomplete-field {
  .fade-up-enter-active,
  .fade-up-leave-active {
    transition: opacity 0.425s,
      transform 0.425s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .fade-up-enter,
  .fade-up-leave-to {
    opacity: 0;
    transform: translate3d(0, 16px, 0);
  }

  input {
    margin-bottom: 0 !important;
  }

  position: relative;
  margin-bottom: 1.25rem;

  &__options {
    position: absolute;
    display: block;
    top: 100%;
    background-color: #fff;
    max-height: 256px;
    width: 360px;
    overflow-y: auto;
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 0.5rem 0;

    &--loading {
      min-height: 64px;
    }
  }

  &__option {
    padding: 0.5rem 1rem;
    font-size: 14px;
    border-bottom: solid 1px $fade-gray;
    cursor: pointer;

    &:last-of-type {
      border-bottom: none;
    }

    &:hover {
      background-color: $fade-gray;
    }
  }
}

.chip {
  display: inline-flex;
  align-items: center;
  flex-basis: content;
  font-size: 0.75rem;
  font-weight: 600;
  border-radius: 4px;
  padding: 0 0.5rem;
  height: 2rem;
  background-color: $bright-green;
  color: $dark-blue;
  margin-right: 0.5rem;

  &__clear {
    display: inline-flex;
    background: transparent;
    border: none;
    margin: 0;
    padding: 0;
    margin-left: 8px;

    svg {
      width: 0.5rem;
      height: 0.5rem;
      fill: #fff;
      cursor: pointer;
    }
  }
}
</style>
